import React from "react"
import { MainPage } from "../MainPage"

const RentalPage = () => {
  return (
    <MainPage
      seoProps={{
        title: `Page not Found`,
      }}
    >
      <h1 className="mt-28">Page Not Found</h1>
    </MainPage>
  )
}

export default RentalPage
